<template>
  <CContainer class="pt-5">
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader class="sub-header">
            <span
              ><strong>{{ title }}</strong></span
            >
          </CCardHeader>
          <CCardBody v-if="items.length > 0">
            <CRow class="mb-3">
              <CCol col="6">
                <strong> Soru (TR - EN - DE)</strong>
                <hr />
              </CCol>
              <CRow class="justify-center">
                <v-col cols="10">
                  <span class="font-weight-bold pb-2 mb-5">
                    {{ " Dil Seçimi : " }}
                  </span>
                  <v-btn-toggle
                    v-model="btnToggleLanguage"
                    borderless
                    mandatory
                    style="width: 90%"
                    active-class="secondary white--text  ">
                    <v-btn
                      small
                      style="width: 50%; border-radius: 50px 0 0 50px"
                      :class="{ red: !btnToggleLanguage }"
                      class="ls-0 text-capitalize font-weight-bold">
                      {{ "ingilizce" }}
                    </v-btn>
                    <v-btn
                      small
                      style="width: 50%; border-radius: 0 50px 50px 0"
                      :class="{ red: btnToggleLanguage }"
                      class="ls-0 text-capitalize font-weight-bold">
                      {{ "almanca" }}
                    </v-btn>
                  </v-btn-toggle></v-col
                >
              </CRow>
              <CCol col="2" class="text-right">
                <strong> Durum</strong>
                <hr />
              </CCol>
              <CCol
                col="2"
                class="text-right"
                v-if="slug == 'CANCEL_QUESTIONS'">
                <strong> Gizlilik</strong>
              </CCol>
              <CCol
                col="2"
                class="text-right"
                v-if="slug == 'SURVEY_QUESTIONS'">
                <strong> Kategori</strong>
                <hr />
              </CCol>
              <CCol col="2" class="text-right">
                <strong> Eylem</strong>
                <hr />
              </CCol>
            </CRow>

            <draggable
              v-model="items"
              group="people"
              @start="drag = true"
              @end="drag = false">
              <CRow v-for="(item, i) in items" :key="i" class="mb-3">
                <CCol col="6" class="d-flex align-items-center">
                  <font-awesome-icon
                    size="lg"
                    :icon="['fas', 'arrows-alt']"
                    class="mr-4 grey" />
                  <CTextarea
                    v-model="item.name"
                    :disabled="item.status == 'passive'"
                    class="mb-0"
                    style="width: 100%"
                    placeholder="Soru" />
                  <CTextarea
                    v-if="!btnToggleLanguage"
                    v-model="item.name_en"
                    :disabled="item.status == 'passive'"
                    class="mb-0 ml-2"
                    style="width: 100%"
                    placeholder="Soru" />
                  <CTextarea
                    v-if="btnToggleLanguage"
                    v-model="item.name_de"
                    :disabled="item.status == 'passive'"
                    class="mb-0 ml-2"
                    style="width: 100%"
                    placeholder="Soru" />
                </CCol>
                <CCol
                  col="2"
                  class="d-flex align-items-center justify-content-end">
                  <CSelect
                    class="mb-0"
                    :value.sync="item.status"
                    :options="status" />
                </CCol>
                <CCol
                  v-if="slug == 'CANCEL_QUESTIONS'"
                  col="2"
                  class="d-flex align-items-center justify-content-end">
                  <CSelect
                    class="mb-0"
                    :value.sync="item.private"
                    :options="cancel_privacy" />
                </CCol>

                <!-- Kategori Survey-->
                <CCol
                  col="2"
                  v-if="slug == 'SURVEY_QUESTIONS'"
                  class="d-flex align-items-center justify-content-end">
                  <CSelect
                    class="mb-0"
                    v-if="slug == 'SURVEY_QUESTIONS'"
                    :value.sync="item.category"
                    :options="kategori" />
                </CCol>
                <CCol
                  col="2"
                  class="d-flex align-items-center justify-content-end">
                  <CButton color="danger" size="lg" @click="deleteItem(item)"
                    >Sil</CButton
                  >
                </CCol>
              </CRow>
            </draggable>
          </CCardBody>
          <CCardFooter align="right">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <strong v-if="items.length == 0"> Kayıtlı bir veri yok </strong>
              </div>
              <div>
                <CButton color="info" class="mr-3" size="lg" @click="addItem()"
                  >Ekle</CButton
                >
                <CButton color="success" size="lg" @click="saveItem()"
                  >Kaydet</CButton
                >
              </div>
            </div>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import draggable from "vuedraggable";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
export default {
  components: {
    draggable,
  },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    slug: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      items: [],
      btnToggleLanguage: 0,
      editedItem: {
        name: "",
        status: "active",
      },
      defaultItem: {
        name: "",
        status: "active",
      },
      status: [
        {
          value: "active",
          label: "Aktif",
        },
        {
          value: "passive",
          label: "Pasif",
        },
      ],
      cancel_privacy: [
        {
          value: false,
          label: "Kullanıcı Görebilir",
        },
        {
          value: true,
          label: "Kullanıcılara Kapalı",
        },
      ],
      kategori: [
        {
          value: "cleaning",
          label: "Temizlik",
        },
        {
          value: "vehicle",
          label: "Araç",
        },
        {
          value: "delivery",
          label: "Teslimat",
        },
        {
          value: "staff",
          label: "Personel",
        },
        {
          value: "renticar",
          label: "Renticar",
        },
      ],
    };
  },
  created() {
    this.data();
  },
  methods: {
    addItem() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
      this.items.push(this.editedItem);
    },
    deleteItem(item) {
      var index = this.items.indexOf(item);
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu ögeyi silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.items.splice(index, 1);
          }
        });
    },
    async saveItem() {
      try {
        await axios.put(
          process.env.VUE_APP_API_URL + `admin/singular?slug=${this.slug}`,
          {
            items: this.items,
          },
          { withCredentials: true }
        );
        Vue.swal.fire({
          icon: "success",
          showCloseButton: true,
          text: "Kaydetme Başarılı",
        });
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: "Bir hata oluştu.",
        });
      }
    },
    async data() {
      try {
        var result = await axios.get(
          process.env.VUE_APP_API_URL + `admin/singular?slug=${this.slug}`,

          { withCredentials: true }
        );

        this.items = result.data.data.items;
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-header {
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: rgb(228, 228, 228);
  border-color: #d8dbe0;
  color: #000 !important;
}
</style>
